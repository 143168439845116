import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import MentionsContainer from '@/containers/Mentions/MentionsContainer';

import { LegalPagesQuery } from '../../../graphql-types';

interface Props {
  data: LegalPagesQuery;
}

export const query = graphql`
  query MentionsShopLegalPages {
    prismicLegalPages {
      data {
        meta_title_mentions
        meta_description_mentions
        ...Mentions
      }
    }
  }
`;

const Mentions = ({ data }: Props): JSX.Element => {
  const { meta_title_mentions, meta_description_mentions } =
    data?.prismicLegalPages?.data || {};
  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title_mentions || 'title'}
        description={meta_description_mentions || 'description'}
      />
      <MentionsContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Mentions;
