import React from 'react';
import { RichText } from 'prismic-reactjs';
import MentionsContent from '@/containers/Mentions/MentionsContent';

import useHtmlSerializer from '@/prismic/useHtmlSerializer';

import { LegalTemplateQuery } from '../../../graphql-types';

interface Props {
  data: LegalTemplateQuery;
  type?: string;
}

const MentionsContainer = ({ data }: Props): JSX.Element => {
  const htmlSerializer = useHtmlSerializer();
  const { mentions_page } = data?.prismicLegalPages?.data || {};

  const MentionsProps = {
    mentions: (
      <RichText render={mentions_page?.raw} htmlSerializer={htmlSerializer} />
    ),
  };
  return (
    <>
      <MentionsContent {...MentionsProps} />
    </>
  );
};

export default MentionsContainer;
